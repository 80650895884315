<template lang="pug">
.wf-table.full-box
  DataTable(
    v-if="showTable"
    ref="dataTable"
    serverSide
    isSelection
    resource="/meterReading/billList"
    size="medium"
    :filterFields="filterFields"
    :columns="columns"
    :defaultSortBy="[{prop: 'createTime', order: 'desc'}]"
    :extraFilterBy="extraFilterBy"
    :toolbarButtonList="toolbarButtonList"
    :operateButtonList="operateButtonList"
    :operationWidth="100"
    :cellClassNameFunc="readingStatusClassFunc"
    @selectionChange="handleSelectionChange")
  </template>

<script>
import { mapActions } from 'vuex'
import { readingStatusClassFunc } from '@/utils/common.js'

export default {
  name: 'MeterReadingCheck',
  data () {
    const actionRights = this.$getPageActions([
      { name: 'makeBill', url: '/meterRead/meterReadingCheck/makeBill' },
      { name: 'check', url: '/meterRead/meterReadingCheck/check' }
    ])
    return {
      showTable: false,
      extraFilterBy: {
        readingStatus: 'READED'
      },
      readingStatusClassFunc,
      // 表格筛选表单配置
      filterFields: [{
        name: 'usewaterCode',
        label: '用水户号',
        form: {
          tag: 'input',
          like: true
        }
      }, {
        name: 'customerCode',
        label: '用户编号',
        form: {
          tag: 'input',
          like: true
        }
      }, {
        name: 'customerName',
        label: '用户姓名',
        form: {
          tag: 'input',
          like: true
        }
      }, {
        name: 'orgId',
        label: '水厂',
        form: {
          tag: 'select',
          filterable: true,
          multiple: true,
          collapseTags: true,
          clearable: true,
          relation: 'waterWorks'
        }
      }, {
        name: 'readingStatus',
        label: '状态',
        showable: false,
        form: {
        }
      }],
      // 表格列配置
      columns: [
        { name: 'readingStatus', label: '抄表状态', relation: 'readingStatus', render: { type: 'select' } },
        { name: 'usewaterCode', label: '用水户号', minWidth: 120 },
        {
          name: 'customerName',
          label: '用户',
          width: 160,
          render: (value, field, data) => {
            const { customerName, customerCode } = data
            return `${customerName}(${customerCode})`
          }
        },
        { name: 'lastReadingNum', label: '上次抄表行码', minWidth: 120 },
        { name: 'thisReadingNum', label: '本次抄表行码', minWidth: 120 },
        { name: 'quantity', label: '水量' },
        { name: 'estimate', label: '是否估抄', render: { type: 'yesOrNo' } },
        { name: 'meterCondition', label: '表况', relation: 'meterStatus', render: { type: 'select' } },
        { name: 'usewaterAddress', label: '用水地址', minWidth: 200 },
        { name: 'readingStaffName', label: '抄表员', minWidth: 100 },
        { name: 'meterNo', label: '水表编号' },
        { name: 'meterType', label: '水表类型', relation: 'meterType', render: { type: 'select' } },
        { name: 'orgName', label: '水厂', minWidth: 140 }
      ],
      toolbarButtonList: actionRights.makeBill ? [{
        label: '批量开账',
        name: 'bill',
        type: 'primary',
        func: this.showConfirm
      }] : [],
      operateButtonList: [{
        label: '审核',
        name: 'check',
        type: 'text',
        textType: 'primary',
        showabled: actionRights.check,
        func: ({ data }) => {
          this.$router.push({
            name: 'recordCheck',
            query: {
              recordId: data.id
            }
          })
        }
      }],
      selections: []
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    getData () {
      this.$refs.dataTable.reRenderTableData()
    },
    renderRelation () {
      this.getRelations(['readingStatus', 'meterStatus', 'meterType', 'waterWorks'])
        .then(res => {
          this.$renderRelationColumns(res, [this.columns, this.filterFields])
          this.$nextTick(() => { this.showTable = true })
        })
    },
    handleSelectionChange (rows) {
      this.selections = rows
    },
    showConfirm () {
      return new Promise((resolve, reject) => {
        if (!this.selections.length) {
          const tip = '您未勾选开帐记录，无法进行批量开帐操作'
          this.$message.warning(tip)
          reject(new Error(tip))
          return
        }
        this.$post({
          url: '/meterReading/openBillStat',
          data: { ids: this.selections.map(i => i.id) }
        }).then(res => {
          if (!res) reject(res)
          this.createConfirmDom(res.data, reject)
          resolve()
        }).catch(reject)
      })
    },
    createConfirmDom (data, reject) {
      const h = this.$createElement
      const columns = [
        { name: 'normalCount', label: '普通用水户', fix: '户' },
        { name: 'normalQuantity', label: '普通用水户水量', fix: 'm³' },
        { name: 'realCount', label: '真实表用水户', fix: '户' },
        { name: 'realQuantity', label: '真实表水户水量', fix: 'm³' },
        { name: 'virualCount', label: '虚拟表用水户', fix: '户' },
        { name: 'virualQuantity', label: '虚拟表用水户水量', fix: 'm³' },
        { name: 'totalCount', label: '总表用水户', fix: '户' }
      ]
      this.$msgbox({
        title: '确认批量开账',
        message: h('div', {}, [
          h('ul', { class: 'bill-total-info' }, columns.map(column => h('li', {}, [
            h('span', { class: 'total-label' }, `${column.label}:`),
            h('span', { class: 'total-content' }, `${data[column.name]}${column.fix}`)
          ]))),
          h('span', { class: 'error-tip' }, '注意：开账后将不可再修改抄表数据！')
        ]),
        showCancelButton: true,
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        beforeClose: async (action, instance, done) => {
          try {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              await this.handleCheckSelections()
              instance.confirmButtonLoading = false
            }
            done()
          } catch (error) {
            done()
          }
        }
      })
        .catch(reject)
    },
    handleCheckSelections () {
      return new Promise((resolve, reject) => {
        const ids = this.selections.map(r => r.id)
        this.$post({
          url: '/meterReading/auditBilling',
          data: { ids }
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            this.$message.success('开账成功')
            this.$refs.dataTable.clearSelection()
            this.getData()
            resolve()
          })
          .catch(reject)
      })
    }
  },
  created () {
    this.renderRelation()
    window.globalEvent.on('renderRecordCheckTable', this.getData)
  },
  beforeDestroy () {
    window.globalEvent.off('renderRecordCheckTable', this.getData)
  }
}
</script>

<style lang="sass" scoped>
.wf-table
  display: flex
  flex: 1
  flex-direction: column
  background-color: #eee
  width: 100%
  height: 100%
.form-box
  position: relative
  padding-right: 8px
.error-tip
  color: $--color-danger
.bill-total-info
  margin-bottom: 8px
  display: grid;
  grid-template-columns: 1fr 1fr
  li
    line-height: 36px
    .total-label
      color: #595959
    .total-content
      margin-left: 8px
      font-weight: 600
</style>
